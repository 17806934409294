/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";

const Index = () => (
    <>
        <noscript>
            <meta httpEquiv="refresh" content="0;url=/en/" />
        </noscript>
    </>
);

export default memo(Index);
